import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from "react-outside-click-handler"
import AdminUploadPreview from './AdminUploadPreview'
import ComplycubeReports from './ComplycubeReports'

const ComplycubeClientReportCheck = ({
  client,
  calculatedRiskAssessment,
  transactionsUserList,
  watchlistView,
}) => {
  const { t } = useTranslation()
  const allChecks = (client && client.complycubeChecks) || []
  const sortedChecks = [...allChecks].sort((a, b) => a.id - b.id)

  const completedChecks = sortedChecks.filter(check => check.status === 'complete')

  const complycubeNav = ['ComplyCube Document', 'ComplyCube Live Photos', 'ComplyCube Live Videos'];
  const [activeComplyCubeTab, setActiveComplyCubeTab] = useState(complycubeNav[0])

  const [visibleComplycubeRWD, setComplyCubeRWD] = useState(false)

  const handleVisibleComplycubeRWDClick = (x) => {
    setActiveComplyCubeTab(x)
    setComplyCubeRWD(false)
  }

  let checks;

  if (watchlistView) {
    checks = completedChecks.filter(check => check.check_type === 'extensive_screening_check')
  } else {
    checks = completedChecks.filter(check => check.check_type !== 'extensive_screening_check')
  }

  if (!checks || !checks.length) {
    return ''
  }

  return (
    <>
      {checks.map((completedCheck) => {
        let transaction;
        if (transactionsUserList && transactionsUserList.objects.length) {
          const completedCheckDate = new Date(completedCheck.created_at);

          transaction = transactionsUserList.objects.find(transaction => {
            const transactionDate = new Date(transaction.created_at);
            return Math.abs(completedCheckDate - transactionDate) < 60*1000;
          })
        }

        return (
          <React.Fragment key={completedCheck.id}>
            <div className="card">
              { watchlistView && (
                <div className="product__line">
                  <div className="product__author">
                    {t('CheckID')}:
                  </div>
                  {completedCheck && completedCheck.check_id}
                  {transaction &&
                    <span style={{marginLeft: "10px"}}>
                      {t('TransID')}:
                      {' '}
                      {`TNX${transaction.id}`}
                    </span>
                  }
                </div>
              )}
              <div className="card__head">
                <div className="title-clear card__title">
                  {t('ComplyCube Reports')}
                </div>
              </div>

              <ComplycubeReports
                reports={[completedCheck.result]}
                calculatedRiskAssessment={calculatedRiskAssessment}
              />
            </div>

            <div className="product__gallery" style={{ marginTop: "16px" }}>
              {watchlistView && completedCheck.complycubeCheckPdf && (
                <AdminUploadPreview
                  upload={completedCheck.complycubeCheckPdf}
                  contentType="application/pdf"
                />
              )}
            </div>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ComplycubeClientReportCheck
