import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from "react-outside-click-handler"
import AdminUploadPreview from './AdminUploadPreview'

const ComplycubeClient = ({
  client,
}) => {
  const { t } = useTranslation()

  const allChecks = (client && client.complycubeChecks) || []
  const sortedChecks = [...allChecks].sort((a, b) => a.id - b.id)

  const completedChecks = sortedChecks.filter(check => check.status === 'complete')

  const complycubeNav = ['ComplyCube Document', 'ComplyCube Live Photos', 'ComplyCube Live Videos', 'Document PDF'];
  const [activeComplyCubeTab, setActiveComplyCubeTab] = useState(complycubeNav[0])

  const [visibleComplycubeRWD, setComplyCubeRWD] = useState(false)

  const handleVisibleComplycubeRWDClick = (x) => {
    setActiveComplyCubeTab(x)
    setComplyCubeRWD(false)
  }

  const livenessCompletedChecks = completedChecks.filter(check => check.check_type !== 'extensive_screening_check')

  if (!livenessCompletedChecks.length) {
    return ""
  }

  return (
    <>
      <div className='card'>
        <div className="card__head">
          <div className="title-clear card__title">
            {t('ClientID')}: {' '}
          </div>
        </div>

        <div className="packages__content nospace__bottom">
          <span>
            {client.client_id}
          </span>
          <div className="space space__16">{' '}</div>

          <div className="card__nav tablet-hide">
            {complycubeNav.map((x, index) => (
              <button
                key={index}
                className={`card__link ${x === activeComplyCubeTab ? "active" : ""}`}
                onClick={() => setActiveComplyCubeTab(x)}
              >
                {t(x)}
              </button>
            ))}
          </div>

          <div className={`custom-dropdown popup__card-nav__mobile tablet-show ${visibleComplycubeRWD ? "dropdown__active" : ""}`}>
            <OutsideClickHandler onOutsideClick={() => setComplyCubeRWD(false)}>
              <div className="custom-dropdown__wrap">
                <div
                  className="select select__small custom-dropdown__head"
                  onClick={() => setComplyCubeRWD(!visibleComplycubeRWD)}
                >
                  <div className="dropdown__selection current">
                    {activeComplyCubeTab}
                  </div>

                  <div className="dropdown__body list">
                    {complycubeNav.map((x, index) => (
                      <div
                        key={index}
                        className={`dropdown__option option ${x === activeComplyCubeTab ? "selected" : ""}`}
                        onClick={() => handleVisibleComplycubeRWDClick(x)}
                      >
                        {t(x)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

            </OutsideClickHandler>
          </div>

          <div className="space space__24">{' '}</div>
          <div className="space space__24">{' '}</div>
          <div className="card__content">
            {activeComplyCubeTab === complycubeNav[0] && (
              <>
                {client.complycubeDocuments.length > 0 ?
                  client.complycubeDocuments.map((upload, index) => (
                    <AdminUploadPreview
                      key={`${upload.storage_name}_${index}`}
                      upload={upload}
                      contentType={upload.mime_type}
                    />
                  )
                ) : t('Not provided') }
              </>
            )}

            {activeComplyCubeTab === complycubeNav[1] && (
              <>
                {client.complycubeLivePhotos &&
                  client.complycubeLivePhotos.length > 0 ?
                  client.complycubeLivePhotos.map((upload, index) => (
                    <AdminUploadPreview
                      key={`${upload.storage_name}_${index}`}
                      upload={upload}
                      contentType={upload.mime_type}
                    />
                  )
                ) : t('Not provided') }
              </>
            )}

            {activeComplyCubeTab === complycubeNav[2] && (
              <>
                {/* {client.complycubeLiveVideos &&
                  client.complycubeLiveVideos.length > 0 ?
                  client.complycubeLiveVideos.map((upload, index) => (
                    <AdminUploadPreview
                      key={`${upload.storage_name}_${index}`}
                      upload={upload}
                      contentType={upload.mime_type}
                    />
                )) : t('Not provided') } */}
                {completedChecks.filter(check => check.check_type === 'enhanced_identity_check').map((check, index) => (
                  <a
                    key={index}
                    href={`https://portal.complycube.com/checks/${check.check_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    { `https://portal.complycube.com/checks/${check.check_id}` }
                  </a>
                ))}
              </>
            )}

            {activeComplyCubeTab === complycubeNav[3] && (
              <>
                {livenessCompletedChecks.length > 0 && livenessCompletedChecks.map((completedCheck) => {
                  return (
                    <React.Fragment key={completedChecks.id}>
                      {completedCheck.complycubeCheckPdf && (
                        <div className="packages__content nospace__bottom" key={completedCheck.id}>
                          <AdminUploadPreview
                            upload={completedCheck.complycubeCheckPdf}
                            contentType="application/pdf"
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ComplycubeClient
